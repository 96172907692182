import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '@/design-system/atoms/Button';
import { ContentContainer } from '@/design-system/atoms/ContentContainer';
import { LinkListItem } from '@/design-system/atoms/LinkListItem';
import { RichTextEditor } from '@/design-system/atoms/RichTextEditor';
import { Text } from '@/design-system/atoms/Text';
import { Theme } from '@/design-system/atoms/Theme';
import { Title } from '@/design-system/atoms/Title';

import styles from './LinkList.module.scss';

const LinkList = ({
    htmlId,
    theme,
    title,
    titleVariant,
    subTitle,
    listItems,
    showDescription = true,
    collapsePadding = false,
    subtext,
    cta,
    analytics,
    ...props
}) => {
    const splitValue = listItems?.length > 0 ? Math.round(listItems.length / 2) : 0;
    const columnOne = listItems?.slice(0, splitValue);
    const columnTwo = listItems?.slice(splitValue);

    return (
        <section
            {...props}
            id={htmlId}
            className={classNames(
                styles[`link-list`],
                collapsePadding && styles[`link-list--collapse-padding`],
            )}
        >
            <Theme name={theme} className={styles[`link-list__wrapper`]}>
                <div className={styles[`link-list__container`]}>
                    <ContentContainer>
                        {(title || subTitle) && (
                            <div className={styles[`link-list__content`]}>
                                {title && (
                                    <Title
                                        tag={Title.TAG.H2}
                                        className={classNames(
                                            styles[`link-list__title`],
                                            titleVariant &&
                                                styles[`link-list__title--${titleVariant}`],
                                        )}
                                        content={title}
                                        lineClamp={{ sm: 4, lg: 2 }}
                                    />
                                )}
                                {subTitle && (
                                    <Text
                                        tag={Text.TAG.P}
                                        variant={Text.VARIANT.T2}
                                        className={styles[`link-list__description`]}
                                        content={subTitle}
                                        lineClamp={{ sm: 4, lg: 2 }}
                                    />
                                )}
                            </div>
                        )}

                        <div role="list" className={styles[`link-list__list`]}>
                            <span role="presentation" className={styles[`link-list__list-column`]}>
                                {columnOne?.map(({ itemDescription, ...item }, index) => (
                                    <LinkListItem
                                        key={index}
                                        {...item}
                                        linkWrapper="li"
                                        description={itemDescription}
                                        analytics={{
                                            componentTitle: title,
                                            ...analytics,
                                        }}
                                    />
                                ))}
                            </span>
                            <span
                                role="presentation"
                                className={classNames(
                                    styles[`link-list__list-column`],
                                    'axe-jest-ignore',
                                )}
                            >
                                {columnTwo?.map(({ itemDescription, ...item }, index) => (
                                    <LinkListItem
                                        key={index}
                                        {...item}
                                        linkWrapper="li"
                                        description={itemDescription}
                                        analytics={{
                                            componentTitle: title,
                                        }}
                                    />
                                ))}
                            </span>
                        </div>
                        {subtext && (
                            <RichTextEditor
                                className={styles[`link-list__subtext`]}
                                text={subtext}
                                analytics={{
                                    componentName: 'Link List',
                                    componentTitle: title,
                                    interactionType: 'internal_link',
                                }}
                            />
                        )}
                        {cta && (cta.href || cta.label) && (
                            <div className={styles[`link-list__cta`]}>
                                <Button
                                    {...cta}
                                    buttonStyle={Button.STYLE.SECONDARY}
                                    analytics={{
                                        componentName: 'Link List',
                                        componentTitle: title,
                                    }}
                                />
                            </div>
                        )}
                    </ContentContainer>
                </div>
            </Theme>
        </section>
    );
};

LinkList.propTypes = {
    /**
     * Theme of the Link List
     */
    theme: PropTypes.oneOf([Theme.NAME.DEFAULT, Theme.NAME.MEDIUM]),
    /**
     * Title of the Link List
     */
    title: PropTypes.string,
    /**
     * Sub Title of the Link List
     */
    subTitle: PropTypes.string,
    /**
     * Show Description of the Link List boolean
     */
    showDescription: PropTypes.bool,
    /**
     * Array of List Items
     */
    listItems: PropTypes.arrayOf(PropTypes.shape({ ...LinkListItem.propTypes })),
    /**
     * Subtext of the Link List
     */
    subtext: PropTypes.node,
    /**
     * CTA Button
     */
    cta: PropTypes.shape(Button.propTypes),
    /**
     * Option to collapse padding
     */
    collapsePadding: PropTypes.bool,
};

export default LinkList;
